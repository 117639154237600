import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import polyhouse_bog_img from "../../Assets/polyhouse_blog_img.webp";
import "animate.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Link } from "react-router-dom";
import "./blog.css";

function Fanpad_Polyhouse_Farming() {
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container fluid>
      <div className="d-flex justify-content-center">
        <img
          src={polyhouse_bog_img}
          className={`card-img-top img-fluid p-3 rounded animate__animated animate__fadeInDown ${
            showImage ? "show" : ""
          }`}
          style={{ width: "90%" }}
          alt="polyhouse farming"
        />
      </div>
      <Row className="m-3">
        <Col className="p-4 textJustify" sm={12} md={10} lg={8} id="content">
          <div id="blog1" className="border p-2">
            <h1 className="text-center p-3">Fan and Pad Polyhouse Farming</h1>

            <h2 className="fw-bold" style={{ display: "inline" }}>
              Fan and Pad Polyhouse Farming
            </h2>
            <span>
              is a revolutionary approach to modern agriculture that leverages{" "}
              <span className="fw-bold">
                advanced cooling systems to create a controlled environment,
              </span>{" "}
              ensuring optimal growth conditions for crops. At{" "}
              <span className="fw-bold">
                Royal Green House Construction Pvt. Ltd. (RGCPL)
              </span>
              , we specialize in designing and constructing{" "}
              <span className="fw-bold">customized Fan and Pad Polyhouses</span>{" "}
              tailored to the specific needs of farmers. Below is an overview of
              the <span className="fw-bold">benefits</span> and the{" "}
              <span className="fw-bold">setup process</span>, that highlights
              our expertise in this innovative farming method.
            </span>
            <h2>Benefits of Fan and Pad Polyhouse Farming</h2>
            <ul>
              <li>
                <span className="fw-bold">Efficient Cooling Mechanism:</span>{" "}
                The Fan and Pad system ensures{" "}
                <span className="fw-bold">optimal temperature regulation</span>,
                even in high ambient heat conditions. This leads to{" "}
                <span className="fw-bold">better crop growth</span> and
                productivity by preventing heat stress.
              </li>
              <li>
                <span className="fw-bold">Improved Humidity Control:</span> The
                system maintains the{" "}
                <span className="fw-bold">ideal humidity</span> for various
                crops, reducing the risk of diseases caused by excess moisture
                or dryness.
              </li>
              <li>
                <span className="fw-bold">Year-Round Farming:</span> With
                consistent climate control, farmers can grow{" "}
                <span className="fw-bold">high-value crops</span> throughout the
                year, achieving{" "}
                <span className="fw-bold">multiple harvests</span> and
                maximizing profits.
              </li>
              <li>
                <span className="fw-bold">Energy Efficiency:</span> Designed to
                operate with{" "}
                <span className="fw-bold">low power consumption</span>, the Fan
                and Pad system minimizes operational costs while delivering
                superior climate control.
              </li>
              <li>
                <span className="fw-bold">Enhanced Crop Quality:</span> By
                maintaining a{" "}
                <span className="fw-bold">consistent environment</span>, the
                system promotes healthier, more uniform crops with higher market
                value.
              </li>
            </ul>
            <h2 className="d-inline-block me-3">Fan and Pad Polyhouse Setup</h2>
            <h3 className="d-inline-block">: RGCPL’s Expert Approach</h3>
            <p>
              RGCPL offers a{" "}
              <span className="fw-bold">step-by-step setup process</span> for
              Fan and Pad Polyhouse Farming to ensure maximum efficiency and
              durability:
            </p>
            <ol>
              <li>
                <span className="fw-bold">Site Assessment:</span> We perform a
                detailed analysis of the site to determine the best orientation,
                ensuring efficient airflow and sunlight penetration.
              </li>
              <li>
                <span className="fw-bold">Foundation Preparation:</span> Our
                team uses{" "}
                <span className="fw-bold">reinforced concrete bases</span> for
                stable and durable structures, accounting for local soil and
                wind conditions.
              </li>
              <li>
                <span className="fw-bold">Structure Installation:</span> Using{" "}
                <span className="fw-bold">galvanized steel pipes</span> and
                high-quality materials, we build polyhouses designed to
                withstand extreme weather conditions.
              </li>
              <li>
                <span className="fw-bold">Fan and Pad System Integration:</span>{" "}
                We install high-efficiency fans and cellulose pads to create a{" "}
                <span className="fw-bold">cooling effect</span> through
                evaporative cooling. This ensures optimal temperature and
                humidity levels for crop growth.
              </li>
              <li>
                <span className="fw-bold">Irrigation and Automation:</span>{" "}
                Advanced irrigation systems like{" "}
                <span className="fw-bold">drip irrigation</span> and{" "}
                <span className="fw-bold">hydroponic setups</span> are
                integrated to maximize water and nutrient efficiency.
              </li>
            </ol>
            <h2>Why Choose RGCPL for Fan and Pad Polyhouse Farming?</h2>
            <ul>
              <li>
                <span className="fw-bold">Tailored Solutions:</span> We provide
                customized designs to meet the specific requirements of
                different crops and climatic conditions.
              </li>
              <li>
                <span className="fw-bold">End-to-End Support:</span> From
                initial consultation to installation and maintenance, RGCPL
                ensures a seamless experience for farmers.
              </li>
              <li>
                <span className="fw-bold">Sustainability Focus:</span> Our
                solutions emphasize{" "}
                <span className="fw-bold">resource conservation</span>, helping
                farmers achieve sustainable agricultural practices.
              </li>
            </ul>
            <p>
              For more information on{" "}
              <span className="fw-bold">Fan and Pad Polyhouse Farming</span>,
              explore our range of{" "}
              <a href="https://royalgreenhouse.co.in/">
                services
              </a>{" "}
              or contact us today.
            </p>

            <h2 className="text-center">
              Start Your Polyhouse Farming Journey with RGCPL Today!
            </h2>
            <div className="d-flex justify-content-center mt-4">
              <Link to="/RequestAQuote">
                <Button variant="success" size="lg">
                  Get a Free Quote Now!
                </Button>
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Fanpad_Polyhouse_Farming;
