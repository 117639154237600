import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import AgreeServicess from "../../Components/AgreeServicess";
import Enquiry from "../../Components/Enquiry";
import aboutImg from "../../Assets/aboutImg0.webp";
import aboutImg2 from "../../Assets/about2.webp";
import Certificate1 from "../../Assets/certificate1.webp";
import Certificate2 from "../../Assets/certificate2.webp";
import Certificate3 from "../../Assets/certificate3.webp";
import Certificate4 from "../../Assets/certificate4.webp";
import Certificate5 from "../../Assets/certificate5.webp";

function Subsidy() {
  return (
    <>
      <Container fluid>
        <Row className="headingimg">
          <Col className="m-5">
            <h1 className="text-white m-4 mx-4 px-2 fw-bold text-center">
              Subsidy
            </h1>
            <div className="text-white m-4 fw-bold text-center">
              <a href="/" className=" text-white m-3 text-decoration-none">
                Home
              </a>
              <i class="fa-solid fa-circle-arrow-right "></i>
              <a className="text-white text-decoration-none m-3">Subsidy</a>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={4}>
            <h1 className="text-green text-center m-4">
              What is Subsidy?
            </h1>
            <p className="m-3">
              {" "}
              The Polyhouse Subsidy is a government-supported financial aid
              provided to farmers to encourage the adoption of polyhouse
              farming, a modern agricultural technique that uses controlled
              environments to enhance crop yields and quality. This subsidy aims
              to reduce the financial burden of constructing polyhouses,
              offering up to 50-90% support depending on the farmer's category,
              region, and scheme guidelines. By promoting protected cultivation,
              the scheme helps farmers grow high-value crops with better
              resource efficiency and resilience against weather changes.
            </p>
            <p className="m-3">
              The National Horticulture Board (NHB) and the National
              Horticulture Mission (NHM) are two major initiatives by the Indian
              government to promote horticulture development, including the
              adoption of polyhouses for protected cultivation. Here's a
              detailed comparison of the two:
            </p>
          </Col>
          <Col lg={4} className="p-3">
            <img
              src={aboutImg}
              alt="hydroponic farming at home"
              className="img-fluid p-3"
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <h2>National Horticulture Board (NHB)</h2>
            <p className="m-4 mt-5">
              The National Horticulture Board (NHB) is a government initiative
              designed to support farmers and entrepreneurs in adopting advanced
              horticulture practices, including polyhouse farming. NHB ensures
              reliable financial assistance to boost productivity and improve
              the quality of produce.
            </p>

            <ul className="m-3">
              <li>
                Provides 50% subsidy on the cost of polyhouse construction, up
                to ₹50 lakh.
              </li>

              
              <li>
                Supports commercial horticulture ventures for fruits,
                vegetables, flowers, and medicinal plants.
              </li>
              <li>
                Financial assistance is released as a back-ended subsidy after
                project completion and verification.
              </li>
              <li>
                Focuses on medium and large-scale farmers to promote high-value
                horticulture crops.
              </li>
              <li>
                Operates at a national level with a credit-linked subsidy model.
              </li>
            </ul>
          </Col>
          <Col lg={6} className="p-3">
            <img
              src={aboutImg}
              alt="hydroponic farming at home"
              className="img-fluid p-3"
            />
          </Col>
        </Row>
        
        <Row className="m-5">
          <h2 className="text-center text-green">Our Certificates</h2>
          
          
        </Row>
      </Container>
      
      <Enquiry />
    </>
  );
}
export default Subsidy;
