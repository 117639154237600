import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Hydroponic_types_img from "../../Assets/Hydroponics_types_img.webp";
import "./blog.css";
import "animate.css";
import { Link } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";

function Types_of_Hydroponics_Systems() {
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container fluid>
      <div className="d-flex justify-content-center">
        <img
          src={Hydroponic_types_img}
          className={`card-img-top img-fluid p-3 rounded animate__animated animate__fadeInDown ${
            showImage ? "show" : ""
          }`}
          style={{ width: "90%" }}
          alt="hydroponic farm setup"
        />
      </div>

      <Row className="m-3">
        <Col className="p-4 textJustify" sm={12} md={10} lg={8} id="content">
          <div id="blog1" className="border p-2">
            <h1 className="text-center p-3">Types of Hydroponic Systems</h1>{" "}
            <p className="pb-4">
              Hydroponics, a revolutionary farming technique, allows plants to
              grow without soil by using a nutrient-rich water solution. This
              method not only accelerates plant growth but also enhances yields,
              making it a preferred choice for farmers worldwide. In this guide,
              we delve into the various{" "}
              <strong>types of hydroponic systems</strong>, their unique
              benefits, and how they cater to different farming needs.
            </p>
            <ol>
              <li className="mb-4">
                <span className="fw-bold">
                  <Link to="/NftAframe" style={{ textDecoration: "none" }}>
                    Nutrient Film Technique (NFT)
                  </Link>
                </span>
                <h6>How It Works:</h6>
                <ul>
                  <li>
                    The Nutrient Film Technique (NFT) involves a shallow stream
                    of nutrient solution flowing through a sloped channel,
                    ensuring constant hydration and nutrient delivery to the
                    plant roots.
                  </li>
                </ul>
                <h6>Advantages:</h6>
                <ul>Highly efficient in terms of water and nutrient use.</ul>
                <ul>Encourages rapid growth and high yields.</ul>

                <h6>Applications:</h6>
                <ul>
                  <li>
                    Ideal for growing leafy greens like lettuce, spinach, and
                    herbs such as basil.
                  </li>
                </ul>
                <h6>Considerations:</h6>
                <ul>
                  <li>
                    Requires constant monitoring to prevent nutrient imbalances
                    or pump failures, as the roots must remain hydrated at all
                    times.
                  </li>
                </ul>
              </li>

              <li className="mb-4">
                <span className="fw-bold">
                  <Link to="/DWC" style={{ textDecoration: "none" }}>
                    Deep Water Culture (DWC)
                  </Link>
                </span>
                <h6>How It Works:</h6>
                <ul>
                  <li>
                    In Deep Water Culture (DWC) systems, plant roots are
                    submerged in a nutrient-rich, oxygenated water reservoir.
                    Air stones or diffusers are used to oxygenate the water,
                    providing the roots with ample oxygen for enhanced nutrient
                    absorption.
                  </li>
                </ul>
                <h6>Advantages:</h6>
                <ul>
                  <li>Simple setup with easy maintenance.</li>
                  <li>
                    Provides excellent oxygenation and nutrient uptake,
                    promoting faster plant growth.
                  </li>
                </ul>
                <h6>Applications:</h6>
                <ul>
                  <li>
                    Suitable for a wide variety of crops, including lettuce,
                    tomatoes, and cucumbers.
                  </li>
                </ul>
                <h6>Considerations:</h6>
                <ul>
                  <li>
                    Requires regular checks to maintain optimal water pH and
                    nutrient levels.
                  </li>
                </ul>
              </li>
              <li className="mb-4">
                <span className="fw-bold">Ebb and Flow (Flood and Drain)</span>
                <h6>How It Works:</h6>
                <ul>
                  <li>
                    In an Ebb and Flow system, plants are placed in a grow tray.
                    The tray is periodically flooded with a nutrient solution,
                    which is then drained back into a reservoir. This system
                    mimics natural watering cycles, promoting robust plant
                    growth.
                  </li>
                </ul>
                <h6>Advantages:</h6>
                <ul>
                  <li>
                    Efficient water usage, with nutrients delivered directly to
                    the roots.
                  </li>
                  <li>
                    Encourages strong root development through periodic wet and
                    dry cycles.
                  </li>
                </ul>
                <h6>Applications:</h6>
                <ul>
                  <li>
                    A versatile system suitable for a variety of crops,
                    including herbs, vegetables, and even flowers.
                  </li>
                </ul>
                <h6>Considerations:</h6>
                <ul>
                  <li>
                    Requires a reliable pump and timer to manage the flooding
                    cycle.
                  </li>
                </ul>
              </li>
              <li className="mb-4">
                <span className="fw-bold">Wick System</span>
                <h6>How It Works:</h6>
                <ul>
                  <li>
                    The Wick system is one of the simplest forms of hydroponics.
                    Plants receive nutrients through a wick or string that draws
                    the solution up from a reservoir to the plant roots. No
                    electricity or pumps are required.
                  </li>
                </ul>
                <h6>Advantages:</h6>
                <ul>
                  <li>
                    Low-cost and easy to set up, making it perfect for
                    beginners.
                  </li>
                  <li>Requires little maintenance, with no moving parts.</li>
                </ul>
                <h6>Applications:</h6>
                <ul>
                  <li>
                    Best for small-scale setups, such as growing herbs and small
                    vegetables.
                  </li>
                </ul>
                <h6>Considerations:</h6>
                <ul>
                  <li>
                    Limited nutrient delivery capability; not ideal for larger
                    plants or crops with high nutrient demands.
                  </li>
                </ul>
              </li>
              <li className="mb-4">
                <span className="fw-bold">Drip System</span>
                <h6>How It Works:</h6>
                <ul>
                  <li>
                    In a Drip system, nutrient solution is delivered directly to
                    each plant through a series of drip emitters or tubes. Any
                    excess solution drains away from the root zone, allowing for
                    precise control over the feeding process.
                  </li>{" "}
                </ul>
                <h6>Advantages:</h6>
                <ul>
                  <li>
                    Offers precise nutrient delivery, which can be adjusted to
                    suit the specific needs of each plant.
                  </li>
                  <li>Works well for large-scale operations.</li>
                </ul>
                <h6>Applications:</h6>
                <ul>
                  <li>
                    Ideal for a wide variety of crops, including vegetables,
                    fruits, and even flowers.
                  </li>
                </ul>
                <h6>Considerations:</h6>
                <ul>
                  <li>
                    Requires regular maintenance to prevent the drippers from
                    clogging.
                  </li>
                </ul>
              </li>
              <li className="mb-4">
                <span className="fw-bold">
                  <Link to="/DutchBucket" style={{ textDecoration: "none" }}>
                    Dutch Bucket System
                  </Link>
                </span>
                <h6>How It Works:</h6>
                <ul>
                  <li>
                    The Dutch Bucket system involves growing plants in
                    individual buckets filled with a growing medium (such as
                    perlite or coco coir). Nutrient solution is delivered to
                    each bucket via a drip system and then recirculated into a
                    central reservoir.
                  </li>
                </ul>
                <h6>Advantages:</h6>
                <ul>
                  <li>Highly scalable and flexible.</li>
                  <li>
                    Ideal for growing larger plants such as tomatoes, peppers,
                    and cucumbers.
                  </li>
                </ul>
                <h6>Applications:</h6>
                <ul>
                  <li>
                    Suitable for crops that require more space and support,
                    making it popular in commercial setups.
                  </li>
                </ul>
                <h6>Considerations:</h6>
                <ul>
                  <li>
                    Requires careful management of the nutrient solution to
                    prevent over-watering.
                  </li>
                </ul>
              </li>
            </ol>
            <p className="text-center">
              The <strong>types of hydroponic systems</strong> discussed above
              provide tailored solutions for diverse farming needs. Whether
              you're a beginner experimenting with the Wick system or a
              commercial farmer leveraging the Dutch Bucket system, hydroponics
              offers unmatched efficiency and scalability. Explore these methods
              to find the best fit for your agricultural goals.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Types_of_Hydroponics_Systems;
