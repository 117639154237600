import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import map1 from "../../Assets/map/map11/map11.webp";
import map2 from "../../Assets/map/map11/map14.webp";
import map3 from "../../Assets/map/map11/bharat.webp";
import map4 from "../../Assets/map/map11/map13.webp";
import map5 from "../../Assets/map/map11/dadhi.webp";
import map6 from "../../Assets/map/map11/map12.webp";
import map7 from "../../Assets/map/map11/client15.webp";
import map8 from "../../Assets/map/map11/client13.webp";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link } from "react-router-dom";

function CustomerExp(props) {
  const renderTooltip1 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Royal Green House has helped me understand the core concept of hydroponic
      AdvanceHydroponicFarmingTechnology I gathered wealth of knowldge. - Pink
      Pearl Jaipur, Rajasthan
    </Tooltip>
  );
  const renderTooltip2 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Royal Green House is the best solution for hydroponic farming. - Farmwala,
      Pune
    </Tooltip>
  );
  const renderTooltip3 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Royal Green House has made hydroponic farming so easy. - Bharat, Bangalore
    </Tooltip>
  );
  const renderTooltip4 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Royal Green House is good for hydroponic farming. - City Greens, Bangalore
    </Tooltip>
  );
  const renderTooltip5 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Royal Green House give me very good knowldge of hydroponic farm. - Joon
      shabbir, Woody wolf farmstead, Mumbai
    </Tooltip>
  );
  const renderTooltip6 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Royal Green House give me very good knowldge of hydroponic farm. -
      Amodhini, Tamilnadu
    </Tooltip>
  );
  const renderTooltip7 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Royal Green House give me very good knowldge of hydroponic farm. - Desai
      agro infra, Gujarat
    </Tooltip>
  );
  const renderTooltip8 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Royal Green House give me very good knowldge of hydroponic farm. -
      Jagdamba Farms, Phaltan
    </Tooltip>
  );
  return (
    <>
      <Container>
        <Row>
          <Col sm={12} className="mt-4 pt-4">
            <h5 className="text-green m-4 text-center fw-bold">
              What Coustomers Say About Us
            </h5>
            <h2 className="text-center m-4 fw-bold">
              Our Customers Share Their Experiences
            </h2>
          </Col>
          <Col sm={12} className="mapbg ">
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip1}
            >
              <img
                src={map1}
                className="rounded-circle img-fluid mapimg1 border border-dark mb-2 border-thick"
                alt="img4"
              />
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip2}
            >
              <img
                src={map2}
                className="rounded-circle img-fluid mapimg2 border border-dark mb-2 border-thick"
                alt="img5"
              />
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip3}
            >
              <img
                src={map3}
                className="rounded-circle img-fluid mapimg3 border border-dark mb-2 border-thick"
                alt="img6"
              />
            </OverlayTrigger>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip4}
            >
              <img
                src={map4}
                className="rounded-circle img-fluid mapimg4 border border-dark mb-2 border-thick"
                alt="img7"
              />
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip5}
            >
              <img
                src={map5}
                className="rounded-circle img-fluid mapimg5 border border-dark mb-2 border-thick"
                alt="img8"
              />
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip6}
            >
              <img
                src={map6}
                className="rounded-circle img-fluid mapimg6 border border-dark mb-2 border-thick"
                alt="img9"
              />
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip7}
            >
              <img
                src={map7}
                className="rounded-circle img-fluid mapimg7 border border-dark mb-2 border-thick"
                alt="img9"
              />
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip8}
            >
              <img
                src={map8}
                className="rounded-circle img-fluid mapimg8 border border-dark mb-2 border-thick"
                alt="img10"
              />
            </OverlayTrigger>
          </Col>
        </Row>
        <Row className="p-2">
          <Col lg={6}>
            <div className="shadow rounded p-3 bg-dark text-white">
              <h6 className="p-1 fw-bold"> Project Development</h6>
              <p className="p-2 text-white">
                Our hydroponic experts provide step-by-step assistance in farm
                setup and operations.
              </p>
              <Link to="/Contact" className="fw-bold btn btn-success">
                <i class="fa-solid fa-circle-arrow-right fa-beat"></i> Talk to
                Us
              </Link>
            </div>
          </Col>
          <Col lg={6}>
            <div className="shadow rounded p-3 bg-dark text-white">
              <h6 className="p-1 fw-bold"> Crop Management</h6>
              <p className="p-2 text-white">
                On-field expertise provided for steady crop management to ensure
                highest produce yield.
              </p>
              <Link to="/Contact" className="fw-bold btn btn-success">
                <i class="fa-solid fa-circle-arrow-right fa-beat"></i> Joint
                Venture
              </Link>
            </div>
          </Col>
          {/* <Col >
                        <h6 className='text-center p-4 text-muted'>Discover independence through the power of HYDROPONICS, <Link to='/Contact' className='text-green text-decoration-none'> Get Selling Support <i class="fa-solid fa-circle-arrow-right fa-beat"></i></Link>
                        </h6>
                    </Col> */}
        </Row>
      </Container>
    </>
  );
}

export default CustomerExp;
