import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import cost_blog_img from "../../Assets/cost_blog_img.webp";
import "./blog.css";
import "animate.css";
import "bootstrap-icons/font/bootstrap-icons.css";

function Lowcost_polyhouse_setup() {
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container fluid>
      <div className="d-flex justify-content-center">
        <img
          src={cost_blog_img}
          className={`card-img-top img-fluid p-3 rounded animate__animated animate__fadeInDown ${
            showImage ? "show" : ""
          }`}
          style={{ width: "90%" }}
          alt="cost of hydroponic and polyhouse farming"
        />
      </div>

      <Row className="m-3">
        <Col className="p-4 textJustify" sm={12} md={10} lg={8} id="content">
          <div id="blog1" className="border p-2">
            <h1 className="text-center p-3">Low Cost Polyhouse Setup</h1>{" "}
            <p className="pb-4">
              For farmers and agripreneurs looking to maximize their
              agricultural output while keeping expenses in check, a low cost
              polyhouse setup offers the perfect solution. At Royal Green House,
              we specialize in providing budget-friendly, high-quality polyhouse
              farming solutions designed to meet diverse agricultural needs.
              Here’s a breakdown of the costs and benefits of various polyhouse
              setups that cater to modern farming demands:
            </p>
            <p></p>
            <p>
              <ol>
                <li>
                  <span className="fw-bold">
                    <a href="/Nvph">NVPH Polyhouse</a> - ₹1200 per sqm:
                  </span>
                </li>
                <p>
                  The Natural Ventilated Polyhouse (NVPH) offers a balance
                  between cost and efficiency. This structure is perfect for
                  regions with moderate climates, providing optimal air
                  circulation to support crops while shielding them from extreme
                  weather. With a low cost polyhouse setup price of ₹1200 per
                  sqm, it allows farmers to regulate temperature and humidity
                  effectively. The Natural Ventilated Polyhouse (NVPH) strikes
                  an excellent balance between affordability and functionality.
                  Ideal for moderate climates, this setup enhances air
                  circulation, shields crops from harsh weather, and maintains a
                  controlled environment. Priced at ₹1200 per sqm, it is an
                  efficient option for growing a variety of crops.
                </p>
                <li>
                  <span className="fw-bold">
                    <a href="/ClimateControlled">Fan Pad Polyhouse</a> - ₹1800
                    per sqm:
                  </span>
                </li>
                <p>
                  The Fan Pad Polyhouse provides advanced climate control for
                  high-value crops. While slightly more expensive, this setup,
                  priced at ₹1800 per sqm, offers a sophisticated evaporative
                  cooling system to ensure consistent growing conditions, making
                  it a premium choice for serious farmers seeking a low cost
                  polyhouse setup with advanced features.{" "}
                </p>
                <li>
                  <span className="fw-bold">
                    <a href="/TunnelShadeHouse">Tunnel Net House</a> - ₹700 per
                    sqm:
                  </span>
                </li>
                <p>
                  A truly low cost polyhouse setup, the Tunnel Net House is an
                  affordable option at ₹700 per sqm. It offers basic protection
                  from pests and minor climate fluctuations, making it ideal for
                  farmers working with a limited budget. This structure ensures
                  effective crop safety at a minimal cost.
                </p>
                <li>
                  <span className="fw-bold">
                    <a href="/Flat_Shade_Net_House">Flat Net House</a> - ₹600
                    per sqm:
                  </span>
                </li>
                <p>
                  The Flat Net House is another economical solution, especially
                  suited for nurseries and shade-loving crops. With a cost of
                  ₹600 per sqm, it delivers excellent pest protection and
                  improved air circulation, proving to be a cost-effective low
                  cost polyhouse setup for small-scale growers.
                </p>
                <li>
                  <span className="fw-bold">
                    <a href="/RainProtectedWireRopeNetHouse">
                      Rain Protected Wire Rope Net House
                    </a>{" "}
                    - ₹900 per sqm:
                  </span>
                </li>
                <p>
                  Specifically designed for regions with high rainfall, this low
                  cost polyhouse setup safeguards crops from excess rain and
                  humidity. Priced at ₹900 per sqm, it is a dependable choice
                  for protecting rain-sensitive crops while maintaining
                  affordability.
                </p>
              </ol>
            </p>
            <p>
              For those exploring sustainable farming options, integrating
              hydroponic farming systems within these low cost polyhouse setups
              can optimize water usage and enhance yields. Although the
              hydroponic farming cost depends on the chosen structure and
              system, it remains a smart investment for modern agriculture.
            </p>
            <p>
              Each low cost polyhouse setup is tailored to meet specific crop
              and climate requirements, providing scalable solutions for both
              small-scale and large-scale farmers. If you’re looking to adopt a
              low cost polyhouse setup, our comprehensive solutions guarantee
              affordability without compromising on quality.
            </p>
            <p>
              For further inquiries or assistance in choosing the right
              structure, you can reach out to us via email at{" "}
              <a href="mailto:info@royalgreenhouse.co.in" target="_blank">
                info@royalgreenhouse.co.in
              </a>{" "}
              or visit us at our Satara location:
            </p>
            <p>
              <a href="https://maps.app.goo.gl/zaBudTaYEaCEqjAJ8">
                <i
                  className="fas fa-map-marker-alt"
                  style={{ color: "green", marginRight: "5px" }}
                ></i>
                #F2/B, Sairatn Building, Survey No 517/5, Visava Naka, Satara,
                Maharashtra, 415002
              </a>
              <br />
              <a href="tel:+919960991166">
                <i
                
                  className="fas fa-phone"
                  style={{ color: "green", marginRight: "5px" }}
                ></i>
                +91-9960-991-166
              </a>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
export default Lowcost_polyhouse_setup;
