import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import factory_img from "../../Assets/gallery/factory12.webp";
import "./blog.css";
import "animate.css";
import "bootstrap-icons/font/bootstrap-icons.css";

function Hydroponic_innovation_in_india() {
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);
  return (
    <Container fluid>
      <div className="d-flex justify-content-center">
        <img
          src={factory_img}
          className={`card-img-top img-fluid p-3 rounded animate__animated animate__fadeInDown ${
            showImage ? "show" : ""
          }`}
          style={{ width: "90%" }}
          alt="top 10 hydroponics crops"
        />
      </div>

      <Row className="m-3">
        <Col className="p-4 textJustify" sm={12} md={10} lg={8} id="content">
          <div id="blog1" className="border p-2">
            <h1 className="text-center p-3">Hydroponic Innovation in India</h1>{" "}
            <p className="pb-4">
              In the realm of modern agriculture, Royal Green House Construction
              (RGCPL) leads the way in hydroponic innovation in India, offering
              cutting-edge solutions that empower growers to embrace sustainable
              farming practices. From climate-controlled polyhouses to advanced
              hydroponic systems, RGCPL is at the forefront of transforming
              Indian agriculture with expertise, innovation, and a passion for
              environmental stewardship. Here’s how RGCPL is pioneering a new
              era of farming in India.
            </p>
            <h4>Why Choose RGCPL?</h4>
            <ol>
              <li className="pb-4">
                <h6>Expertise in Polyhouse Construction</h6>
                <p>
                  RGCPL excels in building high-quality polyhouses that provide
                  controlled environments, protecting crops from extreme weather
                  conditions and creating optimal growth settings. With a deep
                  understanding of diverse crop needs, climate variations, and
                  structural designs, we deliver customized solutions for
                  farmers across India. Our in-house gutter manufacturing
                  ensures top-notch quality and cost-effectiveness, reinforcing
                  our commitment to excellence.
                </p>
              </li>
              <li className="pb-4">
                <h6>Hydroponic Innovation for Sustainable Farming</h6>
                <p>
                  As a leader in hydroponic innovation in India, RGCPL provides
                  state-of-the-art hydroponic systems that maximize water
                  efficiency, nutrient delivery, and crop yields. Our solutions
                  cater to urban farmers and large-scale agricultural
                  enterprises alike, delivering consistent and high-quality
                  produce. Partnering with Bluelab, a global leader in precision
                  tools, we equip our clients with the technology to monitor and
                  optimize nutrient levels, pH, and key growth factors.
                </p>
              </li>
              <li className="pb-4">
                <h6>Comprehensive Range of Services</h6>
                <p>
                  RGCPL offers a full suite of services, from consultation and
                  design to construction and maintenance. Whether you're new to
                  hydroponics or an experienced commercial grower, our team
                  ensures seamless support at every stage. From small-scale
                  setups to extensive installations, we deliver solutions
                  tailored to your unique needs, enabling sustainable growth and
                  innovation.
                </p>
              </li>
              <li className="pb-4">
                <h6>In-House Manufacturing for Quality Assurance</h6>
                <p>
                  Quality is central to RGCPL's operations. Our in-house
                  manufacturing facility enables us to produce premium
                  components, including gutters, ensuring durability and high
                  performance. This approach not only enhances the quality of
                  our projects but also reduces lead times and costs, making us
                  a trusted partner for innovative agricultural solutions.
                </p>
              </li>
              <li className="pb-4">
                <h6>Trusted by Growers Nationwide</h6>
                <p>
                  RGCPL's reputation for excellence is backed by a growing list
                  of satisfied clients nationwide. From hydroponic farms to
                  climate-controlled polyhouses, our projects have transformed
                  agricultural practices. With a commitment to hydroponic
                  innovation in India, we empower farmers to adopt modern
                  techniques for increased productivity and sustainability.
                </p>
              </li>
            </ol>
            <h4>Our Core Competencies:</h4>
            <ol>
              <li className="pb-4">
                <h6>Advanced Polyhouse Construction:</h6>
                <p>
                  {" "}
                  RGCPL specializes in constructing robust polyhouses tailored
                  to diverse crop needs. With precise designs ensuring proper
                  ventilation, lighting, and temperature control, our structures
                  optimize growth and productivity. We maintain strict quality
                  standards to deliver reliable solutions for Indian growers.
                </p>
              </li>
              <li className="pb-4">
                <h6>Hydroponic Farming Solutions:</h6>
                <p>
                  RGCPL is revolutionizing agriculture with hydroponic farming,
                  enabling crop cultivation without soil using nutrient-rich
                  water systems. Ideal for water-scarce regions and urban
                  farming, our hydroponic setups enhance resource efficiency
                  while delivering superior yields year-round. As a pioneer in
                  hydroponic innovation in India, we empower farmers to produce
                  sustainably and profitably.
                </p>
              </li>
              <li className="pb-4">
                <h6>Technical Support and Training:</h6>
                <p>
                  Success in hydroponic farming requires knowledge and
                  precision. RGCPL provides comprehensive training and ongoing
                  technical support, helping farmers master advanced
                  agricultural techniques. From nutrient management to
                  troubleshooting, we ensure our clients achieve optimal
                  results.
                </p>
              </li>
              <li className="pb-4">
                <h6>Sustainable Practices for a Greener Tomorrow:</h6>
                <p>
                  {" "}
                  Sustainability drives our approach. By promoting hydroponics
                  and efficient resource use, RGCPL helps farmers reduce
                  environmental impact while achieving exceptional productivity.
                  Our focus on soilless farming and innovative practices
                  positions us as a leader in shaping the future of agriculture
                  in India.
                </p>
              </li>
            </ol>
            <h4>Client-Centered Approach</h4>
            <p>
              Every RGCPL project is a partnership. We collaborate closely with
              our clients to address specific needs, whether it's a small urban
              farm or a large-scale hydroponic setup. With solutions tailored to
              each project's goals, we deliver unmatched quality and innovation.
            </p>
            <p className="pb-4">
              Our recent projects highlight our expertise in hydroponic
              innovation in India, ranging from urban hydroponic systems to
              commercial-scale installations. As demand grows for soilless
              farming methods such as cocopeat growbags and high-tech
              hydroponics, RGCPL stands ready to meet diverse agricultural
              challenges with cutting-edge solutions.
            </p>
            <h4>Join the RGCPL Community</h4>
            <p className="pb-4">
              Are you ready to transform your farming experience? With RGCPL,
              you’re not just investing in infrastructure—you’re investing in a
              future of sustainable and profitable agriculture. Visit [Royal
              Green House](
              <a href="https://royalgreenhouse.co.in">
                https://royalgreenhouse.co.in
              </a>
              ) to learn more about our offerings and get started on your
              journey toward agricultural innovation. Together, let's cultivate
              a brighter, greener future for India.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
export default Hydroponic_innovation_in_india;
